<template>
    <data-grid-row class="cursor-pointer">
        <data-grid-cell>
            <div class="flex flex-1 items-center gap-x-3">
                <div class="rounded-full bg-primary-100 p-2.5">
                    <base-icon
                        name="line-icons:files:folder"
                        variant="primary"
                    />
                </div>
                <accountant-clients-documents-items-form-name
                    v-model="state.name"
                    :loading="loading"
                    :error-message="errorMessage"
                    @on-submit="onSaveName"
                />
            </div>
        </data-grid-cell>
    </data-grid-row>
</template>

<script setup>
const emit = defineEmits(['created-directory'])

const props = defineProps({
    tenantId: {
        type: String,
        required: true,
    },
    parentId: {
        type: String,
        required: true,
    },
})

const state = reactive({
    name: null,
})

const { execute, loading, errorMessage } = useApi(
    '/api/accountant-documents/directories',
    'POST'
)

const onSaveName = (name) => {
    execute({
        data: {
            name,
            id: props.parentId,
        },
        headers: {
            tenant: props.tenantId,
        },
    }).then(() => {
        emit('created-directory')
    })
}
</script>
